import {
  Label,
  LabelVariants,
  Tooltip,
  Icon,
  Skeleton,
  typography,
  spacerSizes,
  Popover,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';
import { SxStyleProp } from 'theme-ui';

import { PricingItemDisplayMode } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { PassengerPrice } from '@Components/Price/PassengerPrice';
import { StaticPrice } from '@Components/Price/StaticPrice';
import { DataStoreName } from '@Components/Price/usePassengerPrice';
import { Separator } from '@UX/Separator/Separator';

const SUB_FIELD_HEIGHT = typography.label.smallbold.lineHeight as number;

export function getFormattedPrice(
  value: number,
  usePerPersonPrice: boolean,
  includedLabel: string,
  suffixStyles?: SxStyleProp,
  store?: DataStoreName,
  allowZeroPrice: boolean = false,
) {
  if (value === 0 && !allowZeroPrice) {
    return <Fragment>{includedLabel}</Fragment>;
  }

  if (usePerPersonPrice) {
    return (
      <PassengerPrice
        store={store}
        value={value}
        suffixType="short"
        suffixStyles={suffixStyles}
        decimal
      />
    );
  }

  return (
    <StaticPrice
      value={value}
      decimal
    />
  );
}

interface BasketSummaryDetailSkeletonProps {
  hasDescription: boolean;
  labelStyleVariant: LabelVariants;
  valueStyleVariant: LabelVariants;
  hasSubfield?: boolean;
}

const BasketSummaryDetailSkeleton: React.FC<BasketSummaryDetailSkeletonProps> = ({
  hasDescription,
  labelStyleVariant,
  valueStyleVariant,
  hasSubfield = false,
}) => {
  const labelHeight = typography.label[labelStyleVariant].lineHeight as number;
  const valueHeight = typography.label[valueStyleVariant].lineHeight as number;

  const descriptionHeight = hasDescription
    ? (typography.label.small.lineHeight as number) + spacerSizes['4xs']
    : 0;

  const fullLabelHeight = labelHeight + descriptionHeight;

  const skeletonHeight = fullLabelHeight > valueHeight ? fullLabelHeight : valueHeight;

  return (
    <Fragment>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginY: '3xs',
          gap: 's',
        }}
      >
        <Skeleton
          sx={{
            height: skeletonHeight,
            width: '100%',
            backgroundColor: 'backgroundLightneutral',
            borderRadius: 4,
          }}
        />
        <Skeleton
          sx={{
            height: skeletonHeight,
            width: 100,
            backgroundColor: 'backgroundLightneutral',
            borderRadius: 4,
          }}
        />
      </div>
      {hasSubfield && (
        <div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginY: '3xs',
            gap: 's',
          }}
        >
          <Skeleton
            sx={{
              height: SUB_FIELD_HEIGHT,
              width: '100%',
              backgroundColor: 'backgroundLightneutral',
              borderRadius: 4,
            }}
          />
          <Skeleton
            sx={{
              height: SUB_FIELD_HEIGHT,
              width: 100,
              backgroundColor: 'backgroundLightneutral',
              borderRadius: 4,
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

interface BasketSummaryDetailProps extends ClassNameProps {
  label: string;
  labelStyleVariant?: LabelVariants;
  description?: string;
  descriptionTextSize?: LabelVariants;
  disclaimer?: string;
  value: number;
  valueStyleVariant?: LabelVariants;
  labelStyles?: SxStyleProp;
  valueLabelStyle?: SxStyleProp;
  suffixStyles?: SxStyleProp;
  usePerPersonPrice?: boolean;
  tooltipContent?: React.ReactNode;
  loading?: boolean;
  store?: DataStoreName;
  allowZeroPrice?: boolean;
  subField?: string;
  subFieldValue?: number;
  showSeparator?: boolean;
  displayMode?: PricingItemDisplayMode;
  priceQuoteUnit?: string;
  popoverContent?: React.ReactNode;
}

export const BasketSummaryDetail: React.FC<BasketSummaryDetailProps> = ({
  label,
  value,
  description,
  disclaimer,
  subField,
  subFieldValue,
  priceQuoteUnit,
  displayMode = 'ALWAYS',
  labelStyleVariant = 'smallbold',
  valueStyleVariant = 'mediumbold',
  labelStyles = {},
  valueLabelStyle = {},
  suffixStyles = {},
  usePerPersonPrice = false,
  tooltipContent,
  popoverContent,
  loading = false,
  'data-id': dataId,
  store = 'ORDER',
  allowZeroPrice = false,
  showSeparator = false,
}) => {
  const { t } = useTranslation();

  if (displayMode !== 'ALWAYS' && displayMode !== priceQuoteUnit) {
    return;
  }

  if (loading) {
    return (
      <BasketSummaryDetailSkeleton
        hasDescription={!!description}
        labelStyleVariant={labelStyleVariant}
        valueStyleVariant={valueStyleVariant}
        hasSubfield={!!subField}
      />
    );
  }

  return (
    <Fragment>
      <div
        data-id={`basket-summary-details-${dataId}`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginY: '3xs',
          gap: 's',
        }}
      >
        <div>
          <Label
            variant={labelStyleVariant}
            sx={{
              ...labelStyles,
              display: 'block',
            }}
          >
            {label}
          </Label>
          {description && (
            <Label
              variant="small"
              sx={{
                color: 'textDimmedheavy',
                paddingTop: '4xs',
                display: 'block',
              }}
            >
              {description}
            </Label>
          )}
        </div>
        <div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {disclaimer && (
            <Label
              variant="small"
              sx={{
                color: 'textDimmedheavy',
                paddingX: '4xs',
              }}
            >
              {disclaimer}
            </Label>
          )}
          <Label
            variant={valueStyleVariant}
            sx={{
              ...labelStyles,
              ...valueLabelStyle,
              display: 'block',
            }}
          >
            {getFormattedPrice(
              value,
              usePerPersonPrice,
              t('included'),
              suffixStyles,
              store,
              allowZeroPrice,
            )}
          </Label>
          {tooltipContent && (
            <Tooltip
              content={tooltipContent}
              sx={{
                marginLeft: '4xs',
                position: 'relative',
                top: '5xs',
              }}
            >
              <Icon
                name="Markers/Tooltip"
                size="16"
              />
            </Tooltip>
          )}
          {popoverContent && (
            <Popover
              content={popoverContent}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '3xs',
              }}
            >
              <Icon
                size="20"
                name="Markers/Tooltip"
              />
            </Popover>
          )}
        </div>
      </div>
      {!!subFieldValue && (
        <div
          sx={{
            color: 'textDimmedheavy',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 'xs',
          }}
        >
          <Label variant="small">{subField}</Label>
          <Label variant="small">
            {getFormattedPrice(
              subFieldValue,
              usePerPersonPrice,
              t('included'),
              suffixStyles,
              store,
              allowZeroPrice,
            )}
          </Label>
        </div>
      )}
      {showSeparator && (
        <Separator
          lineStyle="solid"
          color="strokeLightneutral"
          sx={{
            margin: '__none',
            width: 'auto',
            marginX: '2px',
          }}
        />
      )}
    </Fragment>
  );
};
